import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import Benefit from "./Components/Benefit";
import Share from './Components/Home/ShareIdea';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header navToggle />
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        {/* <Route element={<AllPlaylist/>} exact path="/playlist" />
        <Route element={<Details/>} exact path="/library/:id" /> */}
        <Route element={<Home />} path="/" />
        <Route element={<About/>} path="/about"/>
        <Route element={<Benefit/>} path="/benefit"/>
        <Route element={<Share/>} path="/share"/>
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
