import React from "react";
import styled, { keyframes } from "styled-components";
import dash from "../../assets/dashboard.svg";

const Benefit: React.FC = () => {
  return (
    <Wrapper>
      <h6>BENEFITS</h6>
      <h1>Help Your Community Enjoy These </h1>
      <Content>
        <Left>
          <h5>Additional Layer of Convenience</h5>
          <h5>
            Seamless Communication <br />
            Keep your community informed and engaged with the real-time
            communication capabilities that EstateIQ offers.
          </h5>
          <h5>
            Additional Layer of Security <br />
            From recent happenings of security breaches within some residential
            estates
          </h5>
          <h5>
            Fully Cloud Managed Services
            <br /> Managing IT assets can be an expensive, time-consuming, and
            daunting task
          </h5>
        </Left>
        <Right>
          <img src={dash} alt="EstateIQ Dshboard" />
        </Right>
      </Content>
      <CTA>Let’s get started</CTA>
    </Wrapper>
  );
};

export default Benefit;

const Wrapper = styled.div`
  width: 1245px;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 123px;
  h6 {
    font-size: 12px;
    letter-spacing: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--mainBlue);
  }
  h1 {
    font-size: 51px;
    letter-spacing: -2px;
    line-height: 61px;
    width: 634px;
    margin: auto;
  }
  @media (max-width: 1336px) {
    width: 1100px;
  }
  @media (max-width: 1100px) {
    width: 900px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    h1 {
      width: 90%;
      margin: auto;
      font-size: 36px;
      line-height: 50px;
    }
  }
  @media (max-width: 426px) {
    width: 95%;
    margin: auto;
    h1 {
      font-size: 32px;
      line-height: 38px;
    }
  }
`;

const ColorOne = keyframes`
0% {
	
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
33% {
  
  color:black;
  border-bottom: 7px solid #EF9C50;
}
66%{
  color:black;
  border-bottom: 7px solid #EF9C50;
}
100% {

  color:black;
  border-bottom: 7px solid #EF9C50;
}
`;
const ColorTwo = keyframes`
0% {
	
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
33% {
  
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
66% {

  color:black;
  border-bottom: 7px solid #EF9C50;
}
100% {

  color:black;
  border-bottom: 7px solid #EF9C50;
}
`;
const ColorThree = keyframes`
0% {
	
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
33% {
  
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
66% {

  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
100%{
  color:black;
  border-bottom: 7px solid #EF9C50;
}

`;
const ColorFour = keyframes`
0% {
	
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
25% {
  
  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
50% {

  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
75% {

  color:#eaeaea;
  border-bottom: 7px solid #eaeaea;
}
100% {

  color:black;
  border-bottom: 7px solid #EF9C50;
}

`;
const LineOne = keyframes`
0% {
	
  border-bottom: 7px solid #eaeaea;
}
33% {
  
  border-bottom: 7px solid #EF9C50;
}
66%{
  border-bottom: 7px solid #EF9C50;
}
100% {

  border-bottom: 7px solid #EF9C50;
}
`;
const LineTwo = keyframes`
0% {
	
  border-bottom: 7px solid #eaeaea;
}
33% {
  
  border-bottom: 7px solid #eaeaea;
}
66% {

  border-bottom: 7px solid #EF9C50
}
100% {

  border-bottom: 7px solid #EF9C50;
}
`;
const LineThree = keyframes`
0% {
	
  border-bottom: 7px solid #eaeaea;
}
33% {
  
  border-bottom: 7px solid #eaeaea;
}
66% {

  border-bottom: 7px solid #eaeaea;
}
100%{
  border-bottom: 7px solid #EF9C50;
}

`;
const LineFour = keyframes`
0% {
	
  border-bottom: 7px solid #eaeaea;
}
25% {
  
  border-bottom: 7px solid #eaeaea;
}
50% {

  border-bottom: 7px solid #eaeaea;
}
75% {

  border-bottom: 7px solid #EF9C50;
}
100% {

  border-bottom: 7px solid #EF9C50;
}

`;
const Content = styled.div`
  display: flex;
  padding-top: 148px;
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 426px) {
    padding-top: 50px;
  }
`;
const Left = styled.div`
  margin-left: 89px;
  text-align: left;
  width: 517px;

  h5 {
    margin-bottom: 90px;
    margin-top: 0px;
    font-size: 24px;
    letter-spacing: -1px;
    line-height: 28px;
    padding-bottom: 10px;

    // hr {
    //   width: 336px;
    //   // border-bottom: 7px solid #ef9c50;
    //   border-color:transparent;
    //   text-align: left;
    //   margin-right: auto;
    //   position: absolute;
    //   outline: 0;
    //   :first-child{
    //     animation-name: ${LineOne};
    //     animation-duration:5s;
    //     animation-direction: normal;
    //     animation-iteration-count: infinite;
    //   }
    //   :nth-child(2){
    //     animation-name: ${LineTwo};
    //     animation-duration: 5s;
    //     animation-direction: normal;
    //     animation-iteration-count: infinite;
    //   }
    //   :nth-child(3){
    //     animation-name: ${LineThree};
    //     animation-duration: 5s;
    //     animation-direction: normal;
    //     animation-iteration-count: infinite;
    //   }
    //   :nth-child(4){
    //     animation-name: ${LineFour};
    //     animation-duration: 5s;
    //     animation-direction: normal;
    //     animation-iteration-count: infinite;
    //   }
    // }
    :first-child {
      animation-name: ${ColorOne};
      animation-duration: 5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
    }
    :nth-child(2) {
      animation-name: ${ColorTwo};
      animation-duration: 6s;
      animation-direction: normal;
      animation-iteration-count: infinite;
    }
    :nth-child(3) {
      animation-name: ${ColorThree};
      animation-duration: 6.2s;
      animation-direction: normal;
      animation-iteration-count: infinite;
    }
    :nth-child(4) {
      animation-name: ${ColorFour};
      animation-duration: 6.4s;
      animation-direction: normal;
      animation-iteration-count: infinite;
    }
  }
  @media (max-width: 1336px) {
    margin-left: 69px;
    width: 500px;
    h5 {
      font-size: 22px;
      line-height: 26px;
    }
  }
  @media (max-width: 1100px) {
    margin-left: 0px;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 426px) {
    width: 90%;
    margin: auto;
    h5 {
      font-size: 14px;
      line-height: 16px;
      width: 244px;
      margin-right: auto;
      hr {
        width: 243px;
      }
    }
  }
`;
const Right = styled.div`
  right: 0;
  bottom: -5px;
  position: absolute;
  img {
    right: 0;
    bottom: 0px;
  }
  @media (min-width: 1800px) {
    position: relative;
    img {
      position: relative;
    }
  }
  @media (max-width: 1336px) {
    width: 500px;
    img {
      width: 500px;
    }
  }
  @media (max-width: 1100px) {
    position: relative;
    margin-top: 20px;
    margin-left: auto;
    width: 646px;
    img {
      width: 646px;
      margin-left: auto;
    }
  }
  @media (max-width: 768px) {
    width: 400px;
    img {
      width: 400px;
    }
  }
  @media (max-width: 576px) {
    width: 308px;
    margin-left: auto;
    img {
      width: 308px;
      margin-left: auto;
    }
  }
`;

const CTA = styled.div`
  background-color: var(--mainBlue);
  width: 133px;
  color: white;
  padding: 12px 66px;
  margin-left: 89px;
  border-radius: 6px;
  @media (max-width: 1100px) {
    margin: auto;
    margin-top: 30px;
  }
`;
