import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";
import styled from "styled-components";
import Testifier from "../../assets/testifier.svg";
let owl_carousel = require("owl.carousel");
window.fn = owl_carousel;

const Testimonial = () => {
  return (
    <Wrapper>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav
        items="1"
        autoplay
        dots
      >
        <div className="item">
          <TestimonialContainer>
            <Left>
              <img src={Testifier} alt="Testifier" />
              <Name>Lorem Ipsum</Name>
              <Title>
                Lorem Ipsum Secondus il, repeestus macha"ii
              {/* Chairman, Ogudu GRA Resident Association, Lagos. */}
              </Title>
            </Left>
            <Right>
              <Quote>“</Quote>
              <QuoteText>Extraordinarily easy to use product.</QuoteText>
            </Right>
          </TestimonialContainer>
        </div>
        <div className="item">
          <TestimonialContainer>
            <Left>
              <img src={Testifier} alt="Testifier" />
              <Name>Akinwunmi John</Name>
              <Title>
               Estate Developer – Cravings Parks, Lagos
              </Title>
            </Left>
            <Right>
              <Quote>“</Quote>
              <QuoteText>Extraordinarily easy to use product.</QuoteText>
            </Right>
          </TestimonialContainer>
        </div>
        <div className="item">
          <TestimonialContainer>
            <Left>
              <img src={Testifier} alt="Testifier" />
              <Name>Lorem Ipsum</Name>
              <Title>
                {/* Managing Director, Cambridge Broadband Network Africa Limited */}
                Adelipsituv' secourtois Krascheur malibu lorem
              </Title>
            </Left>
            <Right>
              <Quote>“</Quote>
              <QuoteText>Extraordinarily easy to use product.</QuoteText>
            </Right>
          </TestimonialContainer>
        </div>
      </OwlCarousel>
    </Wrapper>
  );
};

export default Testimonial;

const Wrapper = styled.div`
  @media (min-width: 1366.1px) {
    width: 1336px;
    margin: auto;
  }
  @media (max-width: 1366px) {
    width: 1000px;
    margin: auto;
  }
  @media (max-width: 1024px) {
    width: 900px;
    margin: auto;
  }
  @media (max-width: 991px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 426px) {
    width: 300px;
    margin: auto;
  }
`;
const TestimonialContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 481px;
  text-align: left;
  @media (max-width: 1024px) {
    width: 351px;
  }
  @media (max-width: 426px) {
    width: 286px;
    margin: auto;
    img {
      width: 286px !important;
      text-align: center;
      margin: auto;
    }
  }
`;
const Right = styled.div`
  width: 342px;
  text-align: left;
  margin-left: 142px;
  @media (max-width: 991px) {
    margin-left: 0px;
    margin-top: 30px;
  }
  @media (max-width: 426px) {
    width: 286px;
    margin: auto;
  }
`;
const Name = styled.div`
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -2px;
  color: white;
  margin-top: 30px;
  margin-bottom: 5px;
`;
const Title = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -1px;
  color: #999999;
  @media (max-width: 426px) {
    width: 226px;
    color:#545454;
  }
`;
const Quote = styled.div`
  color: #3d3d3d;
  font-size: 120px;
  line-height: 30px;
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 991px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media (max-width: 426px) {
    font-size: 80px;
    right: 80px;
  }
`;
const QuoteText = styled.div`
  font-weight: 700;
  font-size: 38px;
  line-height: 49px;
  color: white;
  letter-spacing: -3px;
  @media (max-width: 426px) {
    font-size: 12px;
    line-height: 17px;
    width: 286px;
  }
`;
