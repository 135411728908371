import React from "react";
import styled from "styled-components";
import Mobile from "../../assets/mobile.svg";

const Waitlist: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <h2>
            Join the waitlist to <span>enjoy</span> our mobile app{" "}
          </h2>
          <form>
            <input placeholder="email address" type="email" />
            <button>Join the waitlist</button>
          </form>
        </Left>
        <Right>
          <img src={Mobile} alt="Mobile App" />
        </Right>
      </Container>
    </Wrapper>
  );
};
export default Waitlist;

const Wrapper = styled.div`
  @media (min-width: 1366.1px) {
    width: 1336px;
    margin: auto;
  }
  @media (max-width: 1366px) {
    width: 1000px;
    margin: auto;
  }
  @media (max-width: 1024px) {
    width: 900px;
    margin: auto;
  }
  @media (max-width: 991px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 426px) {
    width: 300px;
    margin: auto;
  }
`;
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--mainBlue);
  height: 438px;
  border-radius: 20px;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
  height: 100%;
  @media (max-width: 426px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const Left = styled.div`
  width: 506px;
  position: relative;
  margin-left: 90px;
  margin-right: 80px;

  h2 {
    font-weight: 700;
    font-size: 38px;
    line-height: 49px;
    letter-spacing: -2px;
    color: white;
    text-align: left;
    width: 358px;
    span {
      color: var(--mainYellow);
    }
  }
  input {
    width: 304px;
    height: 51px;
    background: #eff1ff;
    border-radius: 6px;
    outline: 0;
    padding-left: 18px;
    border-color: transparent;
    ::placeholder {
      font-weight: 500;
      color: #92b0fc;
    }
  }
  button {
    width: 179px;
    border-radius: 6px;
    margin-left: -8px;
    background-color: #2d4bf3;
    height: 58px;
    border-color: transparent;
    outline: 0;
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
  @media (max-width: 1366px) {
    width: 406px;
    margin-left: 45px;
    margin-right: 40px;
    h2 {
      font-size: 30px;
      width: 270px;
    }
    input {
      width: 244px;
    }

    button {
      width: 139px;
    }
  }

  @media (max-width: 991px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 300px;
    text-align: left;
    h2 {
      font-size: 32px;
    }
    form {
      display: flex;
      margin-top: 60px;
    }
    button {
      margin-left: -65px;
    }
  }
  @media (max-width: 426px) {
    width: 300px;
  }
`;
const Right = styled.div`
  text-align: center;
  position: relative;
  width: 483px;
  height: 438px;
  img {
    position: absolute;
    width: 483px;
    // margin-top:;
    right: 0;
    bottom: 0;
  }
  @media (max-width: 1366px) {
    width: 333px;
    height: 333px;
    img {
      width: 333px;
      height: 333px;
    }
  }
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: -14px;
  }
  @media (max-width: 426px) {
    width: 300px;
    margin-bottom: -30px;
    img {
      width: 300px;
    }
  }
`;
