import React,{useEffect} from "react";
import styled from "styled-components";
import comms from "../../assets/comms.svg";
import security from "../../assets/security.svg";
import cloud from "../../assets/cloud.svg";
import convinience from "../../assets/convinience.svg";
import dashboard from "../../assets/dashboard2.svg";
import Testimonial from "./Testimonial";
import Waitlist from "./Waitlist";
import animateScrollTo from "animated-scroll-to";

const Benefits: React.FC = () => {
  useEffect(() => {
    animateScrollTo(0);
  }, []);
  const benefit = [
    {
      heading: "Seamless Communication",
      icon: comms,
      body: "Keep your community informed and engaged with the real time communication capabilities that EstateIQ offers. The estate board and managers can push notifications to all residents at once or a particular category of residents, so the residents can keep updated on the latest happenings. ",
    },
    {
      heading: "Additional Layer of Security",
      icon: security,
      body: "With EstateIQ, estate managers and residents have access to robust and auditable entry/exit data that can be used for investigations, security and facility enhancements. ",
    },
    {
      heading: "Fully Cloud Managed Services",
      icon: cloud,
      body: "Managing IT assets can be an expensive, time-consuming, and daunting task. With EstateIQ, estate managers can focus on strategic needs and reduce costs via cloud based IT services. Our certified support personnel fully manage the underlying cloud and security infrastructure. ",
    },
    {
      heading: "Additional Layer of Convenience",
      icon: convinience,
      body:
        "There is absolutely nothing stopping you from enjoying an additional layer of convenience, with our integrative & intuitive solution (EstateIQ) you can enjoy a great deal of convenience. You have worked hard, and you deserve as much of the convenience we offer, like cutting down waiting times and minimizing human errors.",
    },
  ];
  return (
    <>
      <Wrapper>
        <Container>
          <h1>Benefits</h1>
          <h6>Help your community enjoy these...</h6>
        <Content>
            {benefit.map((item,i)=>(
                <Each key={i}>
                    <img src={item.icon} alt="itmes"/>
                    <h5>{item.heading}</h5>
                    <p>{item.body}</p>
                </Each>
            ))}
            <DashboardImage>
            <img src={dashboard} alt="Estate IQ"/>
            </DashboardImage>
            
        </Content>
        </Container>

      </Wrapper>
      <TestimonialWrapper>
      <Testimonial/>
      </TestimonialWrapper>
      <WaitlistWrapper>
        <Waitlist/>
      </WaitlistWrapper>
    </>
  );
};

export default Benefits;

const Wrapper = styled.div`
  width: 1100px;
  margin: auto;
  padding-top: 229px;
  padding-bottom: 123px;

  @media(min-width:1366.1px){
    width:1367px;
    margin:auto;
  }
  @media(max-width:1366px){
      width:1000px;
      margin:auto;
      
     
  }

  @media (max-width: 991px) {
    width: 700px;
    margin:auto;
    
  }
  @media (max-width: 768px) {
    width: 400px;
    
  }
  @media (max-width: 426px) {
    width: 320px;
  }
`;
const Container = styled.div `
margin-left:20px;
  margin-right:20px;
@media(min-width:1367px){
  margin-left:20px;
  margin-right:20px;
}

h6 {
  font-size: 16px;
  font-weight:500;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 266px;
  text-align:left;
  color: var(--mainGrey);
}
h1 {
  font-size: 51px;
  letter-spacing: -2px;
  line-height: 61px;
  width: 266px;
  text-align:left;
 
}

@media (max-width: 1024px) {
  width:900px;
  margin:auto;
  
}
@media (max-width: 991px) {
  width:400px;
  margin:auto;
  
}
@media(max-width:768px){

  h1 {
    width: 90%;
    font-size: 36px;
    line-height: 50px;
  }
}
@media(max-width:426px){
  width:320px;
  h1{
    width:100%;
  }
}
`
const DashboardImage = styled.div `
background-color:var(--mainYellow);
padding:40px 40px 0px 40px;
border-radius:40px 40px 0px 0px;
height:340px;
margin:auto;
margin-top:100px;
img{
    width:100%;
    height:100%;
}
@media(max-width:1366px){
    height:300px;
}
@media(max-width:1024px){
    height:280px;
    img{
        height:300px;
    }
}
@media(max-width:991px){
  height:128px;
  margin-top:50px;
  padding:10px 10px 0px 10px;
  border-radius:10px 10px 0px 0px;
  img{
    height:130px;
  }
}
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 100px;
  @media (max-width: 768px) {
    margin: auto;
    padding-top: 30px;
  }
  
`;
const Each = styled.div`
  width: 500px;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 40px;
  img {
    width: 54px;
    height: 54px;
  }
  h5 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 28px;
    color: var(--mainBlue);
  }
  p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 24px;
    color: var(--mainGrey);
  }
  @media(max-width:1366px){
      width:450px;
  }
  @media(max-width:991px){
    width:400px;
}
@media(max-width:426px){
  width:320px;
  h5{
    font-size:20px;
    line-height:24px;
  }
  p{
    font-size:12px;
    line-height:19px;
  }
}
  @media(max-width:1024px){
      margin:auto;
  }
`;
const TestimonialWrapper = styled.div `
background-color:#111111;
width:100%;
margin:auto;
padding-top:116px;
padding-bottom:116px;
`
const WaitlistWrapper = styled.div `
background-color:white;
width:100%;
margin:auto;
padding-top:116px;
padding-bottom:116px;
@media(max-width:991px){
  background-color:var(--mainBlue);
  padding-bottom:0px;
  padding-top:87px;
}
`