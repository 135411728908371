import React from "react";
import styled from "styled-components";

export default function Contact() {
  return (
    <>
      <StyledForm>
        <LeftForm>
          <h5>Contact Us.</h5>

          <FormGroup>
            <label>Name</label>
            <input type="name" placeholder="" />
          </FormGroup>
          <FormGroup>
            <label>Email</label>
            <input type="email" />
          </FormGroup>
        </LeftForm>
        <RightForm>
          <textarea placeholder="Your Message"></textarea>
          <button>Send</button>
        </RightForm>
      </StyledForm>
    </>
  );
}

const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 214px;
  padding-bottom: 120px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
const LeftForm = styled.div`
  width: 50%;
  h5 {
    width: 398px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: auto;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: left;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    width: 316px;
    margin: auto;
    h5 {
      width: 316px;
      margin: auto;
    }
  }
`;
const RightForm = styled.div`
  width: 50%;
  border-left: 1px solid #e1e1e1;
  textarea {
    width: 100%;
    height: 370px;
    border: transparent;
    ::placeholder {
      font-size: 18px;
      padding-left: 20px;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--mainBlue);
    height: 77px;
    width: 100%;
    border: transparent;
    outline: 0;
  }
  @media (max-width: 991px) {
    width: 100%;
    border: transparent;
    textarea {
      width: 398px;
      border: 1px solid #e1e1e1;
    }
    button {
      width: 398px;
      margin: auto;
    }
  }
  @media (max-width: 426px) {
    textarea {
      width: 316px;
      margin: auto;
    }
    button {
      width: 316px;
      margin: auto;
    }
  }
`;
const FormGroup = styled.div`
  width: 398px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;
  label {
    font-size: 18px;
    line-height: 18px;
    text-align: left;
  }
  input {
    width: 398px;
    height: 69px;
    border: 1px solid var(--mainGrey);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    @media (max-width: 991px) {
      width: 100%;
      margin: auto;
    }
  }
  @media (max-width: 426px) {
    width: 316px;

    input {
      width: 316px;
      margin: auto;
    }
  }
`;
