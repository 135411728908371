import React, { useEffect } from "react";
import styled from "styled-components";
import animateScrollTo from "animated-scroll-to";

export default function Share() {
  useEffect(() => {
    animateScrollTo(0);
  });
  return (
    <>
      <Wrapper>
        <Container>
          <StyledForm>
            <LeftForm>
              <h5>Share an Idea with us.</h5>

              <FormGroup>
                <label>Name</label>
                <input type="name" placeholder="" />
              </FormGroup>
              <FormGroup>
                <label>Email</label>
                <input type="email" />
              </FormGroup>
            </LeftForm>
            <RightForm>
              <textarea placeholder="Share your idea"></textarea>
              <div>
                <input
                  type="checkbox"
                  id="disclaimer"
                  name="vehicle2"
                  value="disclaimer"
                />
                <label htmlFor="disclaimer">
                  {" "}
                  By clicking Share, you agree to the privacy policy and idea
                  sharing agreement
                </label>
              </div>

              <button>Share</button>
            </RightForm>
          </StyledForm>
        </Container>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;
const Container = styled.div`
  @media (min-width: 1366.1px) {
    width: 1336px;
    margin: auto;
  }
  @media (max-width: 1366px) {
    width: 1000px;
    margin: auto;
  }
  @media (max-width: 1024px) {
    width: 700px;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
`;
const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 214px;
  padding-bottom: 120px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
const LeftForm = styled.div`
  width: 50%;
  h5 {
    width: 398px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: auto;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: left;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 426px) {
    width: 316px;
    margin: auto;
    h5 {
      width: 316px;
      margin: auto;
    }
  }
`;
const RightForm = styled.div`
  width: 50%;
  border-left: 1px solid #e1e1e1;
  textarea {
    width: 100%;
    height: 370px;
    border: transparent;
    ::placeholder {
      font-size: 18px;
      padding-left: 20px;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--mainBlue);
    height: 77px;
    width: 100%;
    border: transparent;
    outline: 0;
  }
  div {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 500px;
    font-size: 12px;
    line-height: 19px;
  }
  @media (max-width: 991px) {
    width: 100%;
    border: transparent;
    div {
      width: 398px;
      margin: auto;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 19px;
    }
    textarea {
      width: 398px;
      border: 1px solid #e1e1e1;
    }
    button {
      width: 398px;
      margin: auto;
    }
  }
  @media (max-width: 426px) {
    textarea {
      width: 316px;
      margin: auto;
    }
    div {
      width: 316px;
      font-size: 12px;
      line-height: 19px;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    button {
      width: 316px;
      margin: auto;
    }
  }
`;
const FormGroup = styled.div`
  width: 398px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;
  label {
    font-size: 18px;
    line-height: 18px;
    text-align: left;
  }
  input {
    width: 398px;
    height: 69px;
    border: 1px solid var(--mainGrey);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    @media (max-width: 991px) {
      width: 100%;
      margin: auto;
    }
  }
  @media (max-width: 426px) {
    width: 316px;

    input {
      width: 316px;
      margin: auto;
    }
  }
`;
