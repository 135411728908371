import React from "react";
import styled from "styled-components";
import AboutOne from "../../assets/about1.png";
import AboutTwo from "../../assets/about2.png";
import AboutThree from "../../assets/about3.png";
import Olaitan from "../../assets/olaitan.png";
import Bolaji from "../../assets/bolaji.png";
import Aderolake from "../../assets/aderolake.png";
import Jide from "../../assets/jide.png";
import Contact from "./Contact";

// import animateScrollTo from "animated-scroll-to";

const BorderedPic = (props: any) => {
  return (
    <StyledBorderedPic>
      <img
        src={props.aboutimg}
        alt="About Estate"
        style={{ left: props.left && "10px", right: props.right && "10px" }}
      />
      <BG style={{ backgroundColor: props.bgColor }} />
    </StyledBorderedPic>
  );
};

const BoardMembers = [
  { img: Olaitan, name: "Olaitan Oludare", position: "Co-founder & CEO" },
  { img: Aderolake, name: "Aderolake Oyedotun", position: "People Matters" },
  { img: Jide, name: "Babajide Owosakin", position: "Technology" },
  { img: Bolaji, name: "Bolaji Olayinka", position: "Technology" },
  // { img: "", name: "Samuel John", position: "People & User Acquisition" },
  
  // { img: "", name: "Samuel John", position: "Digital Sales" },
];

export default function Index(props: any) {
  return (
    <>
      <Wrapper style={{ backgroundColor: "white" }}>
        <Container>
          <FlowOne>
            <Left>
              <h2>About Us</h2>
              <p>
              We would love to be recognized for being innovative in how we 
              provide solutions that enhance living conditions and help 
              communities attain higher levels of efficiencies in estate 
              management. We value collaboration, ingenuity, and hospitality.
              </p>
            </Left>
            <Right>
              <BorderedPic aboutimg={AboutOne} bgColor="#1737e5" left={Left} />
            </Right>
          </FlowOne>
        </Container>
      </Wrapper>
      <Wrapper style={{ backgroundColor: "#F6F6F6" }}>
        <Container>
          <FlowTwo>
            <Left>
              <BorderedPic
                aboutimg={AboutTwo}
                bgColor="#171717"
                right={Right}
              />
            </Left>
            <Right className="right-two">
              <h2>
                <span>“</span> One is <span>too small </span>a number to achieve{" "}
                <span>greatness…</span>
              </h2>
              <h4>John C. Maxwell</h4>
            </Right>
          </FlowTwo>
        </Container>
      </Wrapper>
      <Wrapper style={{ backgroundColor: "#1737E5" }}>
        <Container>
          <FlowTwo>
            <Left>
              <BorderedPic
                aboutimg={AboutThree}
                bgColor="#171717"
                right={Right}
              />
            </Left>
            <Right className="right-two">
              <h3>Our Team</h3>
              <h6>
                We are a team of tech enthusiasts, and our goal is to offer
                stakeholders across the real estate markets in Africa, a unified
                and easy to use platform that delivers value through a variety
                of property related products, services and analytics.{" "}
              </h6>
              <h5 style={{ marginTop: "40px" }}>The Values That Drive Us...</h5>
              <p>
                <span>Collaboration, Ingenuity, {'&'} Hospitality. </span>

              </p>
              
            </Right>
          </FlowTwo>
        </Container>
      </Wrapper>
      <Wrapper style={{ backgroundColor: "#F2F6FF" }}>
        <Container>
          <Board>
            <div className="title">
              Decisions <br />{'&'} Directions
            </div>
            <div className="subtitle">
              The EstateIQ product team is one woven with a sense of community
              and collaboration. Our team comprises professionals; estate
              managers, project {'&'} product managers, software developers and user
              acquisition {'&'} sales experts.
            </div>
            <BoardSection>
              {BoardMembers.map((item, i) => (
                <Card key={i}>
                  <div className="card-top">
                    <img src={item.img} alt=" "/>
                  </div>
                  <div className="card-bottom">
                    <div className="name">{item.name}</div>
                    <div className="board-title">{item.position}</div>
                  </div>
                </Card>
              ))}
            </BoardSection>
          </Board>
        </Container>
      </Wrapper>
      <div id="contactform">
        <Wrapper style={{ backgroundColor: "white" }} className="contactform">
          <Container>
            <Contact />
          </Container>
        </Wrapper>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;
const Container = styled.div`
  @media (min-width: 1366.1px) {
    width: 1336px;
    margin: auto;
  }
  @media (max-width: 1366px) {
    width: 1000px;
    margin: auto;
  }
  @media (max-width: 1024px) {
    width: 700px;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
`;
const StyledBorderedPic = styled.div`
  width: 401px;
  height: 401px;
  position: relative;

  img {
    width: 401px;
    height: 401px;
    position: absolute;
    z-index: 1;
    // left: 10px;
  }
  @media (max-width: 576px) {
    width: 340px;
    height: 340px;

    img {
      width: 340px;
      height: 340px;
    }
  }
  @media (max-width: 426px) {
    width: 284px;
    height: 284px;
    img {
      width: 284px;
      height: 284px;
    }
  }
`;
const BG = styled.div`
  width: 401px;
  height: 401px;
  position: absolute;
  z-index: 0;
  top: 10px;
  right: 0;
  @media (max-width: 576px) {
    width: 340px;
    height: 340px;
  }
  @media (max-width: 426px) {
    width: 284px;
    height: 284px;
  }
`;
const FlowOne = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 26px;
  padding-top: 197px;
  padding-bottom: 137px;
  @media (max-width: 1335.9px) {
    margin-left: 36px;
    margin-right: 36px;
  }
  @media (max-width: 1024px) {
    padding-top: 67px;
    flex-direction: column;
  }
`;
const FlowTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 26px;
  padding-top: 197px;
  padding-bottom: 137px;
  @media (max-width: 1335.9px) {
    margin-left: 36px;
    margin-right: 36px;
  }
  @media (max-width: 1024px) {
    padding-top: 67px;
    flex-direction: column-reverse;
  }
  .left-two {
    width: 401px;
    @media (max-width: 1366px) {
      width: 401px;
    }
    @media (max-width: 1024px) {
      width: 400px;
    }
    @media (max-width: 576px) {
      width: 340px;
    }
    @media (max-width: 426px) {
      width: 284px;
    }
  }
  .right-two {
    width: 550px;
    text-align: left;
    h2 {
      font-weight: 700;
      font-size: 64px;
      letter-spacing: -4px;
      position: relative;
      z-index: 2;
      span:first-child {
        color: #e1e1e1;
        font-size: 120px;
        font-family: "Times New Roman", Times, serif;
        position: absolute;
        top: -40px;
        left: -30px;
        z-index: -1;
      }
      span:nth-child(2) {
        color: #e1e1e1;
      }
      span:nth-child(3) {
        color: var(--mainYellow);
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      color: var(--mainGrey);
      margin-top: 0px;
      margin-bottom: 0px;
      span {
        color: var(--mainYellow);
      }
    }
    h3 {
      font-size: 61px;
      color: white;
      margin-bottom: 15px;
      
    }
    h4 {
      font-size: 28px;
      line-height: 36px;
      color: var(--mainBlue);
      letter-spacing: -2px;
      margin-top: 0px;
    }
    h5 {
      font-size: 20px;
      line-height: 24px;
      color: white;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    h6 {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      color: white;
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight:400;
    }
    @media (max-width: 1366px) {
      width: 401px;
      h2 {
        font-size: 51px;
      }
    }

    @media (max-width: 1024px) {
      width: 400px;
      h2 {
        margin-bottom: 30px;
        font-size: 40px;
      }
    }
    @media (max-width: 576px) {
      width: 340px;
      h2 {
        font-size: 32px;
        line-height: 36px;
      }
      p {
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 45px;
      }
    }
    @media (max-width: 426px) {
      width: 284px;
    }
  }
`;

const Left = styled.div`
  width: 500px;
  //   margin: auto;
  text-align: center;

  h2 {
    font-size: 51px;
    line-height: 61px;
    text-align: left;
    color: var(--mainBlue);
  }
  p {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    color: var(--mainGrey);
  }
  @media (max-width: 1366px) {
    width: 401px;
  }

  @media (max-width: 1024px) {
    width: 400px;
    h2 {
      margin-bottom: 30px;
      font-size: 40px;
    }
  }
  @media (max-width: 576px) {
    width: 340px;
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    p {
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 45px;
    }
  }
  @media (max-width: 426px) {
    width: 284px;
  }
`;
const Right = styled.div`
  width: 401px;
  @media (max-width: 1366px) {
    width: 401px;
  }
  @media (max-width: 1024px) {
    width: 400px;
  }
  @media (max-width: 576px) {
    width: 340px;
  }
  @media (max-width: 426px) {
    width: 284px;
  }
`;

const Board = styled.div`
  padding-top: 110px;
  padding-bottom: 110px;
  margin-left: 12px;
  margin-right: 12px;
  .title {
    font-size: 51px;
    line-height: 61px;
    color: var(--mainBlue);
    width: 285px;
    font-weight: bold;
    text-align: left;
    letter-spacing: -3px;
  }
  .subtitle {
    width: 500px;
    font-size: 16px;
      line-height: 28px;
    text-align: left;
    color: var(--mainGrey);
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    width: 550px;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 340px;
    margin: auto;
    .title {
      font-size: 32px;
      line-height: 38px;
    }
    .subtitle {
      width: 340px;
      font-size: 12px;
    }
  }
  @media (max-width: 426px) {
    .title {
      width: 284px;
      margin: auto;
    }
    .subtitle {
      width: 284px;
      margin: auto;
      margin-top: 30px;
    }
  }
`;
const BoardSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 45px;
`;
const Card = styled.div`
  width: 354px;
  margin-top: 40px;
  margin-bottom: 40px;
  .card-top {
    height: 270px;
    background-color: white;
  }
  .card-bottom {
    background-color: var(--mainBlue);
    text-align: left;

    padding: 24px;
  }
  .name {
    color: white;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
  }
  .board-title {
    color: var(--mainYellow);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
  }
  @media (max-width: 1366px) {
    width: 300px;
    .card-top {
      height: 230px;
    }
    .card-bottom {
      padding: 16px 24px;
    }
  }
  @media (max-width: 1024px) {
    width: 330px;
    margin-top: 20px;
    margin-bottom: 20px;
    .card-top {
      height: 250px;
    }
  }
  @media (max-width: 768px) {
    width: 261px;
    .card-top {
      height: 160px;
    }
  }
  @media (max-width: 576px) {
    width: 340px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    .card-top {
      height: 270px;
    }
  }
  @media (max-width: 426px) {
    width: 284px;

    .card-top {
      height: 170px;
    }
  }
`;
