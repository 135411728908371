import React from "react";
import styled from "styled-components";
import heroOne from "../../assets/hero1.png";
import heroTwo from "../../assets/hero2.png";
import heroThree from "../../assets/hero3.png";
import leftSpiral from "../../assets/leftSpiral.svg";
import rightSpiral from "../../assets/rightSpiral.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import Features from "./Features";
import Benefit from "./Benefit";

const Index: React.FC = () => {
  return (
    <>
      <Hero>
        <Container>
          <HeroContent>
            <PreTitle>
              ENHANCING COMMUNICATION, CONVENIENCE AND SECURITY
            </PreTitle>
            <Title>
              Living can be a lot <span>simpler</span> and{" "}
              <span>intuitive</span>
            </Title>
            <SubTitle>
              For Estate Managers {'&'} Residents within Gated Communities
            </SubTitle>
            <ButtonArea>
              <button>Sign In</button>
              <button>
                <FontAwesomeIcon icon={faCloudArrowDown} /> Get the App
              </button>
            </ButtonArea>
            <ImageSec>
              <img src={heroOne} alt="Hero One" />
              <img src={heroTwo} alt="Hero Two" />
              <img src={heroThree} alt="Hero Three" />
              <img src={rightSpiral} alt="right Spiral" />
              <img src={leftSpiral} alt="right Spiral" />
            </ImageSec>
            <div id="features"></div>
          </HeroContent>
        </Container>
      </Hero>

      <FeatureSection>
        <Features />
      </FeatureSection>

      <BenefitSection>
        <Benefit />
      </BenefitSection>
    </>
  );
};

export default Index;

const Hero = styled.div`
  background-color: white;
`;
const Container = styled.div`
  @media (max-width: 1366px) {
    width: 1000px;
    margin: auto;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (min-width: 1336.1px) {
    width: 1336px;
    margin: auto;
  }
`;
const HeroContent = styled.div`
  text-align: center;
  padding-top: 212px;
  padding-bottom: 68px;
  @media (min-width: 1336px) {
    width: 1336px;
    margin: auto;
  }
  @media (max-width: 1100px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const PreTitle = styled.div`
  color: #5376e5;
  letter-spacing: 0.15em;
  font-weight: 700;
  font-size: 12px;
`;
const Title = styled.div`
  span {
    color: #eacb30;
    letter-spacing: -0.06em;
  }
  font-weight: 700;
  font-size: 51px;
  line-height: 61px;
  margin-top: 10px;
  width: 498px;
  margin: auto;
  @media (max-width: 1100px) {
    font-size: 40px;
  }
  @media (max-width: 576px) {
    width: 90%;
    margin: auto;
    font-size: 32px;
    line-height: 36px;
  }
`;

const SubTitle = styled.div`
  color: #545454;
  margin-top: 20px;
  line-height: 28px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 486px;
  text-align: center;
  margin: auto;
  margin-top: 54px;
  button {
    height: 51px;
    border-color: transparent;
    outline: 0;
    border-radius: 6px;
    :first-child {
      background-color: var(--mainBlue);
      width: 255px;
      text-align: center;
      color: white;
    }
    :last-child {
      background-color: #d3e1ff;
      color: var(--mainBlue);
      width: 213px;
    }
    :hover {
      color: var(--mainBlue);
      background-color: #eacb30;
      border: 1px solid #eacb30;
    }
  }
  @media (max-width: 576px) {
    width: 100%;
    flex-direction: column;
    button {
      :first-child {
        margin-bottom: 5px;
        width: 255px;
      }
      :last-child {
        margin-top: 5px;
        width: 255px;
      }
    }
  }
`;
const ImageSec = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  img {
    :first-child {
      transform: rotate(10deg);
      width: 311px;
      height: 294px;
      :hover{
        transform:rotate(0deg);
      }
    }
    :nth-child(2) {
      width: 412px;
      height:400px;
    }
    :nth-child(3) {
      width: 311px;
      height: 294px;
      transform: rotate(-10deg);
      :hover{
        transform:rotate(0deg);
      }
    }
    :nth-child(4) {
      width: 138px;
      height: 62px;
      position: absolute;
      right: 305px;
      bottom: 0;
    }
    :nth-child(5) {
      width: 138px;
      height: 62px;
      position: absolute;
      left: 305px;
      bottom: 0;
    }
  }
  @media(min-width:1366.1px){
    width:1270px;
    margin:auto;
    margin-top: 56px;
  }
  @media(max-width:1366px){
    width:900px;
    margin:auto;
    margin-top:56px;
    img{
    :first-child{
      width:211px;
      height:194px;
    }
    :nth-child(2){
      width:312px;
      height:300px;

    }
    :nth-child(3){
      width:211px;
      height:194px;
    }
    :nth-child(4){
      right:150px;
    }
    :nth-child(5){
      left:150px;
    }
  }
  @media(max-width:991px){
    width:700px;
    margin:auto;
    margin-top:40px;
    img{
    :first-child{
      width:161px;
      height:144px;
    }
    :nth-child(2){
      width:262px;
      height:250px;

    }
    :nth-child(3){
      width:161px;
      height:144px;
    }
    :nth-child(4){
      right:80px;
    }
    :nth-child(5){
      left:80px;
    }
  }
}
  @media(max-width:768px){
    flex-direction:column;
    width:400px;
    margin:auto;
    margin-top:40px;
    img{
      text-align:center;
      margin:auto;
      margin-top:25px;
      :nth-child(2){
        width:200px;
        height:212px;
      }
      :nth-child(4){
        right:10px;
        transform: rotate(-35deg);
        bottom:100px;
        width:80px;
        height:60px;
      }
      :nth-child(5){
        left:10px;
        top:109px;
        transform: rotate(58deg);
        width:80px;
        height:60px;
        
      }
    }
    
    }
    @media(max-width:426px){
      width:320px;
      margin:auto;
      img{
      :nth-child(1){
        width:143px;
        height:135px;
      }
      :nth-child(2){
        width:187px;
        height:181px;
      }
      :nth-child(3){
        width:143px;
        height:135px;
      }
      
    }
    }
  
`;
const FeatureSection = styled.div`
  background-color: var(--lightBlue);
  width: 100%;
  margin: auto;
`;

const BenefitSection = styled.div`
  background-color: var(--mainGold);
  position: relative;
`;
