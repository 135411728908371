import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
import { Link } from "react-router-dom";
import burger from "../assets/burger.svg";
import close from "../assets/close.svg";
// import create from "../assets/create.svg";
// import join from "../assets/join.svg";
import { HashLink as Linker } from "react-router-hash-link";
import animateScrollTo from "animated-scroll-to";

interface Props {
  navToggle: boolean;
}

const Header: React.FC<Props> = (props) => {
  const [navToggle, setNavToggle] = useState(false);
  // const [modalToggle, setModalToggle] = useState(false);
  const ToggleNav = () => setNavToggle(!navToggle);

  // const handleModal = () => setModalToggle(!modalToggle);
  const ScrollToTop = () => {
    animateScrollTo(0);
  };
  const CloseNav = () => {
    setNavToggle(false);
  };
  const ScrollToContact = () => {
    animateScrollTo(document.querySelector(".contactform"));
  };
  const Navigate = [
    { name: "About Us", icon: "", route: "/about" },
    // { name: "Features", icon: "", route:'/feature' },
    { name: "Benefits", icon: "", route: "/benefit" },
  ];
  useEffect(() => {
    ScrollToTop();
  });
  return (
    <>
      <Wrapper>
        <Container navToggle={navToggle}>
          <Nav>
            <LogoContainer>
              <Link to="/" onClick={ScrollToTop}>
                <img src={Logo} alt="Estate IQ Logo" />
              </Link>
            </LogoContainer>
            <LargeDevice>
              {Navigate.map((Links, i) => (
                <NavLink key={i} to={`${Links.route}`} onClick={ScrollToTop}>
                  {Links.name}
                  {Links.icon}
                </NavLink>
              ))}
              <StyledContact to="/#features">Features</StyledContact>
              <StyledContact
                to="/about/#contactform"
                onClick={() => {
                  ScrollToContact();
                }}
              >
                Contact Us
              </StyledContact>
            </LargeDevice>
            <SmallDevice>
              {navToggle && (
                <>
                  {Navigate.map((Links, i) => (
                    <NavLink
                      key={i}
                      to={`${Links.route}`}
                      onClick={() => {
                        ScrollToTop();
                        CloseNav();
                      }}
                    >
                      {Links.name}
                      {Links.icon}
                    </NavLink>
                  ))}
                  <StyledContact
                    to="/#features"
                    onClick={() => {
                      CloseNav();
                    }}
                  >
                    Features
                  </StyledContact>
                  <StyledContact
                    to="/about/#contactform"
                    onClick={() => {
                      ScrollToContact();
                      CloseNav();
                    }}
                  >
                    Contact Us
                  </StyledContact>
                </>
              )}
            </SmallDevice>

            <CTA href="https://app.estateiq.net/auth/create" navToggle={navToggle}>
              Sign Up
            </CTA>
          </Nav>
          <Icons>
            <button onClick={ToggleNav}>
              {navToggle ? (
                <img src={close} alt="Estate IQ close" />
              ) : (
                <img src={burger} alt="Estate IQ hamburger" />
              )}
            </button>
          </Icons>
          {/* {modalToggle ? (
            <SignUpModal navToggle={navToggle}>
              <a href="https://app.estateiq.net/auth/create">
                <EstateCTA>
                  <img src={create} alt="Create Estate IQ" />
                  <RightModalContent>
                    <h2>Create an Estate</h2>
                    <p>
                      Sign up as an estate admin and onboard members of your
                      estate
                    </p>
                  </RightModalContent>
                </EstateCTA>
              </a>
              <a href="https://app.estateiq.net/auth/join/estate">
                <EstateCTA>
                  <img src={join} alt="Join Estate IQ" />
                  <RightModalContent>
                    <h2>Join an Estate</h2>
                    <p>
                    Sign up as a member of an existing estate
                    </p>
                  </RightModalContent>
                </EstateCTA>
              </a>
            </SignUpModal>
          ) : null} */}
        </Container>
      </Wrapper>
    </>
  );
};

export default Header;

const Wrapper = styled.div<Props>`
  background-color: white;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  z-index: 100;
  @media (max-width: 1100px) {
    box-shadow: none;
  }
`;
const Container = styled.div<Props>`
  position: relative;
  @media (min-width: 1336px) {
    width: 1336px;

    margin: auto;
  }
  @media (max-width: 1366px) {
    width: 1000px;
    margin: auto;
  }
  @media (max-width: 1100px) {
    height: ${(props: any) => (props.navToggle ? "100vh" : "40px")};
    width: 100%;
    padding-bottom: ${(props: any) => (props.navToggle ? "0px" : "20px")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    z-index: 4;
  }
`;
const Nav = styled.div`
  display: flex;
  align-items: center;
  margin-left: 113px;
  margin-right: 113px;
  height: 99px;
  @media (min-width: 1366.1px) {
    width: 1336px;
    margin: auto;
  }
  @media (max-width: 1366px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    height: 0px;
    // padding-top:20px;
    // padding-bottom:20px;
    z-index: 1;
  }
`;
const LogoContainer = styled.div`
  margin-right: 42px;
  margin-left: 15px;
  img {
    width: 120px;
    height: 32px;
  }
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    text-align: left;
    width: 100%;
    margin-right: 0px;
    padding-top: 20px;
    // padding-bottom: 20px;

    img {
      width: 90px;
      height: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
const LargeDevice = styled.div`
  display: none;
  @media (min-width: 1100px) {
    display: flex;
  }
`;
const SmallDevice = styled.div`
  width: 100%;

  @media (min-width: 1100px) {
    display: none;
  }
`;
const NavLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  color: var(--mainGrey);
  border: transparent;
  background-color: transparent;
  outline: 0;
  margin-left: 14px;
  margin-right: 14px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-decoration: none;
  img {
    padding-left: 2px;
  }
  :hover {
    color: var(--mainBlue);
    cursor: pointer;
  }
  @media (max-width: 1100px) {
    width: 100%;
    text-align: left;
    jutify-content: left;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 35px;
    padding-bottom: 5px;
    border: 1px solid #f6f6f6;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const StyledContact = styled(Linker)`
  font-weight: 500;
  font-size: 16px;
  color: var(--mainGrey);
  border: transparent;
  background-color: transparent;
  outline: 0;
  margin-left: 14px;
  margin-right: 14px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-decoration: none;
  img {
    padding-left: 2px;
  }
  :hover {
    color: var(--mainBlue);
  }
  @media (max-width: 1100px) {
    width: 100%;
    text-align: left;
    jutify-content: left;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 35px;
    padding-bottom: 5px;
    border: 1px solid #f6f6f6;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const CTA = styled.a<Props>`
  background-color: var(--mainBlue);
  border-radius: 6px;
  padding: 12px 36px;
  color: white;
  margin-left: auto;
  margin-right: 15px;
  border: transparent;
  outline: 0;
  text-decoration:none;
  @media (max-width: 1100px) {
    display: ${(props: any) => (props.navToggle ? "block" : "none")};
    margin-top: 10px;
  }
  :hover {
    cursor: pointer;
  }
`;
const Icons = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
  button {
    background-color: transparent;
    outline: 0;
    border: transparent;
    position: absolute;
    top: 20px;
    z-index: 50;
    right: 16px;
  }
`;
// const SignUpModal = styled.div`
//   position: absolute;
//   width: 500px;
//   right: 35px;
//   top: 80px;
//   background-color: white;
//   padding: 20px;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
//   border-radius: 20px;
//   @media (max-width: 1100px) {
//     top: 350px;
//     right: 15px;
//     display: ${(props: any) => (props.navToggle ? "block" : "none")};
//   }
//   @media (max-width: 576px) {
//     width: 300px;
//     padding: 10px;
//   }
//   a {
//     text-decoration: none;
//   }
// `;
// const EstateCTA = styled.div`
//   display: flex;
//   background-color: #fbfbfb;
//   border-radius: 20px;
//   // width:100%;
//   padding: 30px;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   :hover {
//     cursor: pointer;
//   }
//   img {
//     margin-right: 10px;
//   }
//   @media (max-width: 576px) {
//     img {
//       width: 50px;
//       height: 50px;
//     }
//   }
// `;
// const RightModalContent = styled.div`
//   text-align: left;
//   h2 {
//     font-weight: 700;
//     font-size: 24px;
//     margin-top: 0px;
//     margin-bottom: 5px;
//     letter-spacing: -2px;
//     color: black;
//   }
//   p {
//     margin-top: 0px;
//     margin-bottom: 0px;
//     font-size: 10px;
//     letter-spacing: -0.8px;
//     color: black;
//   }
// `;
