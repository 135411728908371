import React from "react";
import styled from "styled-components";
import featuresOne from "../../assets/featuresOne.svg";
import featuresTwo from "../../assets/featuresTwo.svg";
import featuresThree from "../../assets/featuresThree.svg";
import featuresFour from "../../assets/featuresFour.svg";
import featuresFive from "../../assets/featuresFive.svg";
import {Link} from 'react-router-dom';

const Features: React.FC = () => {
  const Feature = [
    {
      icon: featuresFive,
      title: "Access Control",
      main: "EstateIQ offers a secure, convenient, flexible way of controlling who has access to your building and when access is allowed.",
    },
    {
      icon: featuresOne,
      title: "Billing and Payment System",
      main: "Collection of fees and dues are critical to maintaining infrastructure. EstateIQ makes payments convenient, and collections seamless.",
    },
    {
      icon: featuresTwo,
      title: "Residents' Collaboration",
      main: "EstateIQ enables collaboration among residents as well with the estate board.",
    },
    {
      icon: featuresThree,
      title: "Safety and Security",
      main: "Security is among the most notable considerations when people opt for gated communities. Estate managers can support and strengthen their security architecture with EstateIQ.",
    },
    {
      icon: featuresFour,
      title: "Estate Secretariat/Board",
      main: "The estate office can enhance productivity and ensure effectiveness in communications with residents and vendors.",
    },
    
  ];
  return (
    <Wrapper>
      <h6>FEATURES</h6>
      <h1>A secured platform for gated communities</h1>
      <Content>
        {Feature.map((each, i) => (
          <Each key={i}>
            <img src={each.icon} alt="Features" />
            <h5>{each.title}</h5>
            <p>{each.main}</p>
          </Each>
        ))}
        
      </Content>
      <ShareLink to="/share">Share your idea with us</ShareLink>
    </Wrapper>
  );
};

export default Features;

const Wrapper = styled.div`
  width: 1040px;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 83px;
  h6 {
    font-size: 12px;
    letter-spacing: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--mainBlue);
  }
  h1 {
    font-size: 51px;
    letter-spacing: -2px;
    line-height: 61px;
    width: 634px;
    margin: auto;
  }
  @media (max-width: 1100px) {
    width: 900px;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 400px;
    h1 {
      width: 90%;
      msrgin: auto;
      font-size: 36px;
      line-height: 50px;
    }
  }
  @media (max-width: 426px) {
    width: 320px;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom:80px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;
const Each = styled.div`
  width: 300px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  img {
    width: 46px;
    height: 46px;
  }
  h5 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 28px;
    color: var(--mainBlue);
  }
  p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 24px;
    color: var(--mainGrey);
  }
`;
const ShareLink = styled(Link) `
background-color:var(--mainBlue);
color:white;
text-decoration:none;
padding:15px 30px;
border-radius:6px;
`